@use "@/scss/config" as *;

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    background-color: var(--color-contrast-between_lower_low);
    border-bottom: var(--space-3xs) var(--color-contrast-lower) solid;
    border-radius: var(--space-sm);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    pointer-events: auto;

    padding: var(--space-md) var(--space-sm);
    padding-bottom: var(--space-xs);

    @include min-width(sm) {
        padding: var(--space-md) var(--space-md);
    }

    h1, h2 {
        margin-top: 0;
    }
}
